var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-table",
        {
          ref: "grid",
          attrs: {
            title: `${_vm.deptData.deptName} 5S 점검결과 목록`,
            columns: _vm.grid.columns,
            gridHeight: _vm.grid.height,
            data: _vm.deptData.results,
            columnSetting: false,
            isExcelDown: false,
            usePaging: false,
            noDataLabel: _vm.$message("MSG0000752"),
            hideBottom: true,
            editable: _vm.editable && !_vm.disabled,
            rowKey: "saiPatrolResultId",
            selection: "multiple",
          },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props, col }) {
                return [
                  col.name === "link" && _vm.isApproveComplete
                    ? [
                        _c("q-btn", {
                          attrs: {
                            push: "",
                            size: "xs",
                            icon: "search",
                            color: "blue-6",
                            round: "",
                            dense: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.linkImprDetail(props.row)
                            },
                          },
                        }),
                      ]
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: {
                          showLoading: false,
                          label: "LBLADD",
                          icon: "add",
                        },
                        on: { btnClicked: _vm.addResult },
                      })
                    : _vm._e(),
                  _vm.editable &&
                  !_vm.disabled &&
                  _vm.deptData.results.length > 0
                    ? _c("c-btn", {
                        attrs: {
                          showLoading: false,
                          label: "LBLEXCEPT",
                          icon: "remove",
                        },
                        on: { btnClicked: _vm.removeResult },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }