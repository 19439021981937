<template>
  <div>
    <c-table
      ref="grid"
      :title="`${deptData.deptName} 5S 점검결과 목록`"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="deptData.results"
      :columnSetting="false"
      :isExcelDown="false"
      :usePaging="false"
      :noDataLabel="$message('MSG0000752')"
      :hideBottom="true"
      :editable="editable && !disabled"
      rowKey="saiPatrolResultId"
      selection="multiple"
    >
      <template v-slot:customArea="{ props, col }">
         <template v-if="col.name==='link' && isApproveComplete">
            <q-btn push size="xs" icon="search" color="blue-6" round dense @click="linkImprDetail(props.row)"></q-btn>
        </template>
        <!-- <template v-if="col.name==='customCol'">
          <component
            :is="imprComponent"
            :col="col"
            :props="props"
            :inputEditable="editable&&isApproveComplete"
            :isImmShow="true"
            :requestContentsCols="requestContentsCols"
            tableKey="saiPatrolResultId"
            ibmTaskTypeCd="ITT0000065"
            ibmTaskUnderTypeCd="ITTU000105"
            @imprChange="imprChange"
          />
        </template> -->
      </template>
      <template slot="table-button">
        <q-btn-group outline>
          <!-- 추가 -->
          <c-btn
            v-if="editable && !disabled"
            :showLoading="false"
            label="LBLADD"
            icon="add"
            @btnClicked="addResult"
          />
          <!-- 제외 -->
          <c-btn
            v-if="editable && !disabled && deptData.results.length > 0"
            :showLoading="false"
            label="LBLEXCEPT"
            icon="remove"
            @btnClicked="removeResult"
          />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>

import { uid } from 'quasar'
export default {
  name: 'patrolDeptResult',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        saiPatrolId: '',
        isApprContent: false,
      }),
    },
    // dept filter list용
    deptData: {
      type: Object,
      default: () => ({
        deptCd: '',
        deptName: '',
        results: [],
      })  
    },
    grid: {
      type: Object,
      default: () => ({
        columns: [],
        height: "360px",
        data: [],
      })
    },
    patrol: {
      type: Object,
      default: () => ({
        saiPatrolId: '',
        plantCd: '',
        patrolDate: '',
        patrolName: '',
        patrolResultSummary: '',
        patrolCompleteFlag: '',
        patrolUserIds: '',
        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드
        regUserId: '',
        chgUserId: '',
        results: [],
        deleteResults: [],
      }),
    },
  },
  data() {
    return {
      editable: true,
      saveType: 'POST',
      resultItemUrl: '',
      saveUrl: '',
      deleteUrl: '',
      approvalUrl: '',
      isSave: false,
      isApproval: false,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    isApproveComplete() {
      return Boolean(this.patrol.approvalStatusCd) && this.patrol.approvalStatusCd === 'ASC9999999'
    },
    isOld() {
      return Boolean(this.popupParam.saiPatrolId)
    },
    disabled() {
      return this.patrol.patrolCompleteFlag === "Y"
        // 결재중인 경우 비활성화
        || (this.patrol.approvalStatusCd === 'ASC0000001' && !this.popupParam.isApprContent)
    },
    // // 개선요청 내용 전달
    // requestContentsCols() {
    //   return ['patrolItemName'];
    // },
    // // 개선요청 Component
    // imprComponent() {
    //   return () => import(`${'@/pages/common/ibm/tableImpr.vue'}`);
    // },
    // [E] 결재관련 버튼 컨트롤
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
    },
    addResult() {
      this.patrol.results.splice(0, 0, {
        saiPatrolId: this.popupParam.saiPatrolId,
        deptCd: this.deptData.deptCd,
        saiPatrolResultId: uid(),
        mdmLocationId: '',
        locationName: '',
        patrolContents: '',
        patrolRequestDate: this.$comm.getToday(),
        remark: '',
        regUserId: this.$store.getters.user.userId,
        editFlag: "C",
      });
      this.$emit('setDeptList')
    },
    removeResult() {
      let selectData = this.$refs["grid"].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit("ALERT", {
          title: "LBLGUIDE", // 안내
          message: "MSGNOITEMS", // 선택된 항목이 없습니다.
          type: "warning", // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, (item) => {
          if (!this.patrol.deleteResults) this.patrol.deleteResults = [];
          if (this.$_.findIndex(this.patrol.deleteResults, {
                saiPatrolResultId: item.saiPatrolResultId,
              }) === -1 
            && item.editFlag !== "C"
          ) {
            this.patrol.deleteResults.push(item);
          }
          this.patrol.results = this.$_.reject(this.patrol.results, item);
          this.deptData.results = this.$_.reject(this.deptData.results, item);
        });
      }
    },
    // [E]] 결재관련 
    // 개선창 닫기 후
    // imprChange() {
    //   this.$refs['grid'].$refs['compo-table'].resetVirtualScroll();
    // },
    linkImprDetail(row) {
      this.popupOptions.title = 'LBLIMPROVE';
      this.popupOptions.param = {
        sopImprovementId: row.sopImprovementId,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      // this.popupOptions.width = '90%';
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closeImprPopup;
    },
    closeImprPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.$emit("getDetail")
    },
  }
};
</script>
